// ----------------------------------------------------------------------

const account = {
  displayName: 'Paz Del Rescate',
  email: 'pazdelrescate@gmail.com',
  photoURL: '/static/mock-images/avatars/PDR.jpeg',
};

export default account;

// import { getAuth } from "firebase/auth";

// const auth = getAuth();
// const user = auth.currentUser;


// const account = {
//  displayName: user.displayName,
//  email: user.email,
//  photoURL: '/static/mock-images/avatars/PDR.jpeg',
// };


